.contact-us-page {
    padding: 40px;
    background-color: #f4f4f4;
    margin-top: 60px;
  }
  
  .contact-heading {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 40px;
  }
  
  .contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .contact-details {
    flex: 1;
    max-width: 40%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .contact-details h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #007BFF;
  }
  
  .contact-methods p {
    font-size: 1.1rem;
    color: #555;
  }
  
  .contact-form {
    flex: 1;
    max-width: 50%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .contact-form h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #007BFF;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .form-group textarea {
    height: 150px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .contact-details, .contact-form {
      max-width: 100%;
    }
  }
  