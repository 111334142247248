.watermark-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent interference with page content */
    z-index: 9999; /* Ensure the watermark is on top */
  }
  
  .watermark-text {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7vw;
    color: rgba(0, 0, 0, 0.1); /* Light gray for watermark effect */
    white-space: nowrap;
    user-select: none; /* Prevent text selection */
    transform-origin: center;
    transform: rotate(-30deg) translate(-50%, -50%);
    text-align: center;
  }
  