.services-page {
  padding: 40px;
  margin-top: 40px;
  background-color: #f4f4f4;
}

.services-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

.service-card {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-title {
  font-size: 1.8rem;
  color: #007BFF;
  margin-bottom: 10px;
}

.service-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}