.about-us-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    margin-top: 60px;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #f4f4f4;
  }
  
  .about-header {
    text-align: center;
    background-color: #77b6ca;
    padding: 20px 10px;
    margin-bottom: 30px;
  }
  
  .about-header h2 {
    font-size: 32px;
    color: black;
    font-weight: 700;
  }
  
  .about-header p {
    font-size: 16px;
    margin-top: 7px;
  }
  
  .about-section, .services-section, .mission-section, .contact-section {
   margin-left: 85px;
   margin-right: 85px;
  }
  
  .about-section h2, .services-section h2, .mission-section h2, .contact-section h2 {
    font-size: 24px;
    font-weight: 700;
    margin-left: 21px;

  }
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

  }
  .services-section{
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .service-box {
    flex: 1;
    min-width: 250px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .service-box h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #015e98;
  }
  
  .service-box p {
    font-size: 16px;
  }

  
  .contact-section h2 {
    font-size: 24px;
    font-weight: 700;
  }
  
  .contact-section {
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .our-mission-p {
    margin-left: 23px;
    
  }
  