/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  color: #3d4348;
  background-color: #f8fbfc;
  font-size: 14px;
  padding-bottom: 50px;
  position: relative;
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 25px;
  text-decoration: none
}

.footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-about .logo span {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #015e98;
}

.footer .footer-about p {
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #4d6578;
  font-size: 16px;
  color: #3d4348;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #3b3f43;
  border-color: #3d4348;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
  color: #3e5055;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: #3f4142;
  display: inline-block;
  line-height: 1;
  text-decoration: none
}

.footer .footer-links ul a:hover {
  color: #494b4d;
}

.footer .footer-contact p {
  margin-bottom: 5px;
}

.footer .footer-newsletter .newsletter-form {
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 6px 8px;
  position: relative;
  border-radius: 50px;
  border: 1px solid rgba(137, 201, 241, 0.3);
  /* Replace with desired color and transparency */
  display: flex;
  background-color: white;
  /* Replace with your surface color */
  transition: 0.3s;

}

.footer .footer-newsletter .newsletter-form:focus-within {
  border-color: #015e98;
}

.footer .footer-newsletter .newsletter-form input[type=email] {
  border: 0;
  padding: 4px 10px;
  width: 100%;
  background-color: white;
  color: black;
}

.footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
  outline: none;
}

.footer .footer-newsletter .newsletter-form input[type=submit] {
  border: 0;
  font-size: 16px;
  padding: 0 20px;
  margin: -7px -9px -7px 0;
  background: #015e98;
  color: white;
  transition: 0.3s;
  border-radius: 50px;
}

.footer .footer-newsletter .newsletter-form input[type=submit]:hover {
  background: #015e98;
}

.footer .copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #3f4142;
  ;
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 6px;
  font-size: 13px;
}