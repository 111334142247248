.courses-page {
  padding: 40px;
  background-color: #f4f4f4;
  margin-top: 100px;
}

.courses-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
}

.courses-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

.course-card {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-card:hover {
  transform: translateY(-10px);
}

.course-title {
  font-size: 1.8rem;
  color: #007BFF;
  margin-bottom: 10px;
}

.course-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
}

.course-details {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.course-duration,
.course-price {
  font-weight: bold;
}

.enroll-button {
  padding: 10px 20px;
  background-color: #015e98;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.enroll-button:hover {
  background-color: #0056b3;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background-color: #ffffff;
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid #388da8;
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  animation-delay: -0.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background-color: #388da8;
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #ffffff;
  line-height: 0;
}

.scroll-top:hover {
  background-color: #388da8, transparent 20%;
  color: #ffffff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  --background-color: #3d4348, transparent 96%;
  color: #3d4348;
  background-color: var(--background-color);
  padding: 25px 0;
  position: relative;
}

.page-title h1 {
  font-size: 24px;
  font-weight: 700;
}

.page-title .breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.page-title .breadcrumbs ol li+li {
  padding-left: 10px;
}

.page-title .breadcrumbs ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: #3d4348, transparent 70%;
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: #3d4348;
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 100px;
  overflow: clip;
}

@media (max-width: 1199px) {

  section,
  .section {
    scroll-margin-top: 66px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #388da8;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 70vh;
  position: relative;
  padding: 180px 0 40px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.hero .hero-bg img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero .hero-bg::before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 15%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.hero h1 span {
  color: #388da8;
}

.hero p {
  color: #3d4348, transparent 30%;
  margin: 5px 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .hero .hero-img {
    max-width: 600px;
  }
}

.hero .btn-get-started {
  color: #ffffff;
  background: #388da8;
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 50px;
  transition: 0.5s;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-get-started:hover {
  color: #ffffff;
  background: #388da8, transparent 15%;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: #3d4348;
  font-weight: 600;
}

.hero .btn-watch-video i {
  color: #388da8;
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover {
  color: #388da8;
}

.hero .btn-watch-video:hover i {
  color: color-mix(in srgb, #388da8, transparent 15%);
}

@media (max-width: 640px) {
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .hero p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 13px;
  }
}

/*--------------------------------------------------------------
# Featured Services Section
--------------------------------------------------------------*/
.featured-services {
  padding: 40px 0;
}

.featured-services .service-item {
  position: relative;
  padding-top: 20px;
}

.featured-services .service-item .icon {
  background-color: color-mix(in srgb, #388da8, transparent 80%);
  width: 72px;
  height: 72px;
  position: relative;
  margin-right: 15px;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
}

.featured-services .service-item .icon i {
  color: #388da8;
  font-size: 32px;
  z-index: 2;
  position: relative;
}

.featured-services .service-item .title {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
}

.featured-services .service-item .title a {
  color: var(--heading-color);
}

.featured-services .service-item .title a:hover {
  color: #388da8;
}

.featured-services .service-item .description {
  font-size: 14px;
}

.featured-services .service-item:hover .icon {
  background-color: #388da8;
}

.featured-services .service-item:hover .icon i {
  color: #ffffff;
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about {
  padding-bottom: 20px;
}

.about .content .who-we-are {
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #3d4348, transparent 40%;
}

.about .content h3 {
  font-size: 2rem;
  font-weight: 700;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 1.25rem;
  margin-right: 4px;
  color: #388da8;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .read-more {
  background: #388da8;
  color: #ffffff;
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 12px 24px;
  border-radius: 5px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.about .content .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
}

.about .content .read-more:hover {
  background: color-mix(in srgb, #388da8, transparent 20%);
  padding-right: 19px;
}

.about .content .read-more:hover i {
  margin-left: 10px;
}

.about .about-images img {
  border-radius: 10px;
}

/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients {
  padding: 20px 0;
}

.clients .client-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.clients .client-logo img {
  padding: 20px 40px;
  max-width: 90%;
  transition: 0.3s;
  opacity: 0.5;
  filter: grayscale(100);
}

.clients .client-logo img:hover {
  filter: none;
  opacity: 1;
}

@media (max-width: 640px) {
  .clients .client-logo img {
    padding: 20px;
  }
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-item {
  width: 100%;
  margin-bottom: 15px;
}

.features .nav-item:last-child {
  margin-bottom: 0;
}

.features .nav-link {
  color: var(--heading-color);
  border: 0;
  padding: 30px;
  transition: 0.3s;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
}

.features .nav-link i {
  background-color: var(--surface-color);
  color: #388da8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 48px;
  height: 48px;
  font-size: 22px;
  flex-shrink: 0;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.features .nav-link h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
  transition: 0.3s;
}

.features .nav-link p {
  font-size: 15px;
  margin: 0;
}

.features .nav-link:hover {
  background: color-mix(in srgb, #388da8, transparent 96%);
}

.features .nav-link.active {
  background: color-mix(in srgb, #388da8, transparent 96%);
  color: #3d4348;
}

.features .tab-pane img {
  border-radius: 15px;
}

/*--------------------------------------------------------------
# Features Details Section
--------------------------------------------------------------*/
.features-details .features-item+.features-item {
  margin-top: 60px;
}

.features-details .features-item .content {
  background-color: color-mix(in srgb, #388da8, transparent 95%);
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 30px;
  border-radius: 10px;
}

.features-details .features-item h3 {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 15px;
}

.features-details .features-item .more-btn {
  background-color: #388da8;
  color: #ffffff;
  padding: 6px 30px;
  border-radius: 6px;
}

.features-details .features-item .more-btn:hover {
  background-color: color-mix(in srgb, #388da8, transparent 20%);
}

.features-details .features-item ul {
  list-style: none;
  padding: 0;
}

.features-details .features-item ul li {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.features-details .features-item ul li:last-child {
  padding-bottom: 0;
}

.features-details .features-item ul i {
  font-size: 20px;
  margin-right: 10px;
  color: #388da8;
}

.features-details .features-item img {
  border-radius: 15px;
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  background-color: #ffffff;
  border: 1px solid #ffffff, transparent 85%;
  height: 100%;
  padding: 30px;
  transition: 0.3s;
  border-radius: 10px;
  display: flex;
}

.services .service-item .icon {
  font-size: 32px;
  border-radius: 10px;
  position: relative;
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  flex-shrink: 0;
}

.services .service-item h3 {
  color: color-mix(in srgb, var(--heading-color), transparent 25%);
  font-weight: 700;
  font-size: 22px;
  transition: 0.3s;
}

.services .service-item p {
  margin-bottom: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  transition: 0.3s;
}

.services .service-item .read-more {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  transition: 0.3s;
  font-size: 14px;
}

.services .service-item .read-more i {
  margin-left: 10px;
}

.services .service-item.item-cyan .icon {
  color: #0dcaf0;
  border: 1px solid #0dcaf0;
  background: rgba(13, 202, 240, 0.1);
}

.services .service-item.item-orange .icon {
  color: #fd7e14;
  border: 1px solid #fd7e14;
  background: rgba(253, 126, 20, 0.1);
}

.services .service-item.item-teal .icon {
  color: #20c997;
  border: 1px solid #20c997;
  background: rgba(32, 201, 151, 0.1);
}

.services .service-item.item-red .icon {
  color: #df1529;
  border: 1px solid #df1529;
  background: rgba(223, 21, 4, 0.1);
}

.services .service-item.item-indigo .icon {
  color: #6610f2;
  border: 1px solid #6610f2;
  background: rgba(102, 16, 242, 0.1);
}

.services .service-item.item-pink .icon {
  color: #f3268c;
  border: 1px solid #f3268c;
  background: rgba(243, 38, 140, 0.1);
}

.services .service-item:hover {
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}

.services .service-item:hover h3 {
  color: var(--heading-color);
}

.services .service-item:hover p {
  color: color-mix(in srgb, var(--default-color), transparent 10%);
}

/*--------------------------------------------------------------
# More Features Section
--------------------------------------------------------------*/
.more-features .features-image {
  position: relative;
  min-height: 400px;
}

.more-features .features-image img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.more-features h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.more-features .icon-box {
  margin-top: 30px;
}

.more-features .icon-box i {
  color: #388da8;
  margin-right: 15px;
  font-size: 24px;
  line-height: 1.2;
}

.more-features .icon-box h4 {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
}

.more-features .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.course .course-item {
  background-color: color-mix(in srgb, #388da8, transparent 96%);
  padding: 40px 40px;
  height: 100%;
  border-radius: 15px;
}

.course h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
}

.course h4 {
  color: #388da8;
  font-size: 48px;
  font-weight: 700;
  font-family: var(--heading-font);
  margin-bottom: 0;
}

.course h4 sup {
  font-size: 28px;
}

.course h4 span {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 18px;
  font-weight: 500;
}

.course .description {
  font-size: 14px;
}

.course .cta-btn {
  border: 1px solid var(--default-color);
  color: var(--default-color);
  display: block;
  text-align: center;
  padding: 10px 35px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--heading-font);
  transition: 0.3s;
  margin-top: 20px;
  margin-bottom: 6px;
}

.course .cta-btn:hover {
  background: #388da8;
  color: #ffffff;
  border-color: #388da8;
}

.course ul {
  padding: 0;
  list-style: none;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  text-align: left;
  line-height: 20px;
}

.course ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.course ul li:last-child {
  padding-bottom: 0;
}

.course ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.course ul .na {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.course ul .na i {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.course ul .na span {
  text-decoration: line-through;
}

.course .featured {
  position: relative;
}

.course .featured .popular {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #388da8;
  color: #ffffff;
  padding: 4px 15px 6px 15px;
  margin: 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.course .featured .cta-btn {
  background: #388da8;
  color: #ffffff;
  border-color: #388da8;
}

@media (max-width: 992px) {
  .course .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .course .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .course .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
# Faq Section
--------------------------------------------------------------*/
.faq .section-title {
  padding-bottom: 20px;
}

.faq .faq-container .faq-item {
  position: relative;
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  border-radius: 5px;
  overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.faq .faq-container .faq-item h3 .num {
  color: #388da8;
  padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
  color: #388da8;
}

.faq .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
  overflow: hidden;
}

.faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
  color: #388da8;
}

.faq .faq-container .faq-active {
  background-color: #388da8 transparent 97%;
  border-color: #388da8, transparent 80%;
}

.faq .faq-container .faq-active h3 {
  color: #388da8;
}

.faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: #388da8;
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  background-color: var(--surface-color);
  box-shadow: 0px 0 20px rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  padding: 30px;
  margin: 40px 30px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: 0.3s;
}

.testimonials .testimonial-item .stars {
  margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid var(--background-color);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin: 0;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}

.testimonials .swiper-wrapper {
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #388da8;
}

.testimonials .swiper-slide {
  opacity: 0.3;
}

@media (max-width: 1199px) {
  .testimonials .swiper-slide-active {
    opacity: 1;
  }

  .testimonials .swiper-pagination {
    margin-top: 0;
  }

  .testimonials .testimonial-item {
    margin: 40px 20px;
  }
}

@media (min-width: 1200px) {
  .testimonials .swiper-slide-next {
    opacity: 1;
    transform: scale(1.12);
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
  background-color: var(--surface-color);
  padding: 20px 0 30px 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.contact .info-item i {
  font-size: 20px;
  color: #388da8;
  width: 56px;
  height: 56px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  border: 2px dotted color-mix(in srgb, #388da8, transparent 40%);
}

.contact .info-item h3 {
  font-size: 20px;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .php-email-form {
  background-color: var(--surface-color);
  height: 100%;
  padding: 30px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
  .contact .php-email-form {
    padding: 20px;
  }
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: var(--surface-color);
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: #388da8;
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
  color: #ffffff;
  background: #388da8;
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: color-mix(in srgb, #388da8, transparent 20%);
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .service-box {
  background-color: var(--surface-color);
  padding: 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.service-details .service-box+.service-box {
  margin-top: 30px;
}

.service-details .service-box h4 {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 92%);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.service-details .services-list {
  background-color: var(--surface-color);
}

.service-details .services-list a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  background-color: color-mix(in srgb, var(--default-color), transparent 96%);
  display: flex;
  align-items: center;
  padding: 12px 15px;
  margin-top: 15px;
  transition: 0.3s;
}

.service-details .services-list a:first-child {
  margin-top: 0;
}

.service-details .services-list a i {
  font-size: 16px;
  margin-right: 8px;
  color: #388da8;
}

.service-details .services-list a.active {
  color: #ffffff;
  background-color: #388da8;
}

.service-details .services-list a.active i {
  color: #ffffff;
}

.service-details .services-list a:hover {
  background-color: color-mix(in srgb, #388da8, transparent 95%);
  color: #388da8;
}

.service-details .download-catalog a {
  color: var(--default-color);
  display: flex;
  align-items: center;
  padding: 10px 0;
  transition: 0.3s;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.service-details .download-catalog a:first-child {
  border-top: 0;
  padding-top: 0;
}

.service-details .download-catalog a:last-child {
  padding-bottom: 0;
}

.service-details .download-catalog a i {
  font-size: 24px;
  margin-right: 8px;
  color: #388da8;
}

.service-details .download-catalog a:hover {
  color: #388da8;
}

.service-details .help-box {
  background-color: #388da8;
  color: #ffffff;
  margin-top: 30px;
  padding: 30px 15px;
}

.service-details .help-box .help-icon {
  font-size: 48px;
}

.service-details .help-box h4,
.service-details .help-box a {
  color: #ffffff;
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 26px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: #388da8;
}

/*--------------------------------------------------------------
# Starter Section Section
--------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}